import { SttTextItem } from '@stt-componentes/core';
import React from 'react';
import moment from 'moment';

export const SIGLA_MODALIDADE = {
    DERMATOLOGIA: 'VLP',
    ELETROCARDIOGRAFIA: 'ECG',
    ESPIROMETRIA: 'ESP',
    ELETROENCEFALOGRAFIA: 'EEG',
    ESTOMATOLOGIA: 'EST',
    POLISSONOGRAFIA: 'PSG'
};

export function adicionarMascaraCPF(cpf) {
    if (!cpf) {
        return;
    }
    const cpfPontuado = cpf.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, '$1.$2.$3-$4');
    return cpfPontuado;
}

export function renderItem(item, index) {
    if (item.conteudo && item.conteudo !== '') {
        return <SttTextItem
            key={index}
            title={item.titulo}
            content={item.conteudo}
            classContent={item.classContent} />;
    }
}

export function verificaStringTemplate(parts) {
    var res = parts[0];
    for (var i = 1; i < parts.length; i++) {
        if (arguments[i])
            res += arguments[i];
        res += parts[i];
    }
    res = res.trim();
    return !(!res || res === 'null') ? res : '';
}

export const pushArray = (array, item, inserirTextoPadrao = false, textoPadrao = 'Não informado') => {
    if (item.conteudo && item.conteudo != '') {
        if (typeof item.conteudo == 'string') {
            item.conteudo = item.conteudo.trim();
        }
        array.push({ titulo: item.titulo, conteudo: item.conteudo, classContent: item.classContent });
    } else if (inserirTextoPadrao) {
        array.push({ titulo: item.titulo, conteudo: textoPadrao, classContent: item.classContent });
    }
};

export const retornaSimNao = (valor) => {
    return (valor || valor == 'S') ? 'Sim' : 'Não';
};

export const calcularIdade = (dataNascimento, complementoAnos) => {
    if (moment(dataNascimento).isValid()) {
        return moment().diff(dataNascimento, 'years', false) + ' ' + complementoAnos;
    }
};

export const verificaPossibilidadeImpressao = (sigla) => {
    return sigla == SIGLA_MODALIDADE.DERMATOLOGIA;
};

export const verificaPossibilidadeImpressaoImagens = (sigla) => {
    return sigla == SIGLA_MODALIDADE.ELETROCARDIOGRAFIA ||
        sigla == SIGLA_MODALIDADE.ELETROENCEFALOGRAFIA ||
        sigla == SIGLA_MODALIDADE.POLISSONOGRAFIA ||
        sigla == SIGLA_MODALIDADE.ESPIROMETRIA;
};

export const baixarArquivo = (url, nome = 'estudo.zip') => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_parent');
    link.setAttribute('download', nome);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const downloadImagem = (titulo) => {
    fetch(titulo).then(response => {
        response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            /*eslint-disable */
            a.download = titulo;
            /*eslint-enable */
            a.click();
        });
    });
};

export const Patologia = {
    getProcedenciaPorSigla: (sigla, strings) => {
        switch (sigla) {
            case 'H':
                return strings.procedenciaConj.hu;
            case 'C':
                return strings.procedenciaConj.clinicaOdontologica;
            case 'E':
                return strings.procedenciaConj.externoUFSC;
            default:
                return strings.naoInformado;
        }
    },

    getTipoExame: (sigla, strings) => {
        switch (sigla) {
            case 'A':
                return strings.tipoExameConj.anatomopatologico;
            case 'C':
                return strings.tipoExameConj.citologico;
            default:
                return strings.naoInformado;
        }
    },

    getTipoBiopsia: (sigla, strings) => {
        switch (sigla) {
            case 'I':
                return strings.biopsiaConj.incisional;
            case 'E':
                return strings.biopsiaConj.excisional;
            case 'C':
                return strings.biopsiaConj.curetagem;
            case 'A':
                return strings.biopsiaConj.aspiracao;
            case 'P':
                return strings.biopsiaConj.pecaCirurgica;
            default:
                return strings.naoInformado;
        }
    },

    getTipoLesao: (sigla, strings) => {
        switch (sigla) {
            case 'S':
                return strings.tipoLesaoConj.superficial;
            case 'M':
                return strings.tipoLesaoConj.submucosa;
            case 'C':
                return strings.tipoLesaoConj.subcutanea;
            case 'I':
                return strings.tipoLesaoConj.intraossea;
            default:
                return strings.naoInformado;
        }
    }
};

export const Estomatologia = {
    getLadoAfetadoPorSigla: (sigla, strings) => {
        switch (sigla) {
            case 'U':
                return strings.lesaoAfetadoConj.unilateral;
            case 'B':
                return strings.lesaoAfetadoConj.bilateral;
            case 'L':
                return strings.lesaoAfetadoConj.linhaMedia;
            default:
                return strings.naoInformado;
        }
    },

    getArcadaAfetadaPorSigla: (sigla, strings) => {
        switch (sigla) {
            case 'S':
                return strings.arcadaAfetadaConj.superior;
            case 'I':
                return strings.arcadaAfetadaConj.inferior;
            case 'A':
                return strings.arcadaAfetadaConj.ambas;
            case 'N':
                return strings.arcadaAfetadaConj.naoSeAplica;
            default:
                return strings.naoInformado;
        }
    },

    getOcorrenciaLesao: (sigla, strings) => {
        switch (sigla) {
            case 'U':
                return strings.ocorrenciaLesaoConj.lesaoUnica;
            case 'M':
                return strings.ocorrenciaLesaoConj.lesoesMultiplas;
            default:
                return strings.naoInformado;
        }
    },

    getLocalizacaoDor: (sigla, strings) => {
        switch (sigla) {
            case 'L':
                return strings.localizacaoDorConj.localizada;
            case 'D':
                return strings.localizacaoDorConj.difusa;
            default:
                return strings.naoInformado;
        }
    },

    getEspontaneidadeDor: (sigla, strings) => {
        switch (sigla) {
            case 'E':
                return strings.espontaneidadeDorConj.espontanea;
            case 'P':
                return strings.espontaneidadeDorConj.provocada;
            default:
                return strings.naoInformado;
        }
    },

    getDuracaoDor: (sigla, strings) => {
        switch (sigla) {
            case 'P':
                return strings.duracaoDorConj.passageira;
            case 'C':
                return strings.duracaoDorConj.continua;
            case 'I':
                return strings.duracaoDorConj.intermitente;
            default:
                return strings.naoInformado;
        }
    },

    getIntensidadeDor: (sigla, strings) => {
        switch (sigla) {
            case 'L':
                return strings.intensidadeDorConj.leve;
            case 'M':
                return strings.intensidadeDorConj.moderada;
            case 'U':
                return strings.intensidadeDorConj.intensa;
            default:
                return strings.naoInformado;
        }
    },

    getTipoDor: (sigla, strings) => {
        switch (sigla) {
            case 'P':
                return strings.tipoDorConj.paroxistica;
            case 'U':
                return strings.tipoDorConj.pulsatil;
            case 'Q':
                return strings.tipoDorConj.queimacao;
            default:
                return strings.naoInformado;
        }
    },

    getPossibilidadeTraumaLocal: (sigla, strings) => {
        switch (sigla) {
            case 'M':
                return strings.possibilidadeTraumaLocalConj.mordedura;
            case 'P':
                return strings.possibilidadeTraumaLocalConj.proteseMalAdaptada;
            case 'S':
                return strings.possibilidadeTraumaLocalConj.semTrauma;
            default:
                return strings.naoInformado;
        }
    },

    getUsoHabitos: (sigla, strings) => {
        switch (sigla) {
            case 'S':
                return strings.sim;
            case 'N':
                return strings.nao;
            case 'E':
                return strings.ex;
            default:
                return strings.naoInformado;
        }
    },

    getFrequenciaEtilismo: (sigla, strings) => {
        switch (sigla) {
            case 'T':
                return strings.frequenciaEtilismoConj.todosDias;
            case 'F':
                return strings.frequenciaEtilismoConj.finalSemana;
            default:
                return strings.frequenciaEtilismoConj.semFrequencia;
        }
    },

    getFrequenciaDrogas: (sigla, strings) => {
        switch (sigla) {
            case 'T':
                return strings.frequenciaDrogasConj.todosDias;
            case 'F':
                return strings.frequenciaDrogasConj.finalSemana;
            default:
                return strings.frequenciaDrogasConj.semFrequencia;
        }
    },


};