import React, { useRef, useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttPaper, SttModal, SttBox, SttInputProtocol, SttHeading, SttButton, SttTranslateHook } from '@stt-componentes/core';
import { useMediaQuery } from 'react-responsive';
import { CONSTANTES_MODALIDADES, SITUACAO } from '../../constantes';
import { downloadImagem } from '../../util';
import axios from 'axios';
import { useSignals } from '@preact/signals-react/runtime';
import { carregando, dadosExame, exameSelecionado, menuSelecionado, podeExibirGaleria, telaProtocolo } from '../../signals';
import alerta from '../../signals/alerta';
import HttpStatus from 'http-status-codes';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    heading: {
        marginBottom: theme.spacing(4),
        color: props => {
            switch (props.basename) {
                case 'ufpa':
                    return '#ffffff';
            }
        },
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        }
    },
    duvidasAcessar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        cursor: 'pointer',
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        },
    },
    box: {
        display: 'flex',
        width: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: '100%'
    },
    inputProtocolo: {
        width: '90%'
    }
}));

const PROTOCOLO = 'protocolo';

/**
 * Regra 1 - Manter a ordem do backend para o caso de todos exames terem imagens ou todos os exames não terem imagens.
 * Regra 2 - Ordenar os exames que possuem imagens primeiro. 
 */
const ordenarPelosExamesComImagens = (a, b) => {
    if (a.exame?.possui_imagens === true && b.exame?.possui_imagens !== true) {
        return -1;
    }
    if (a.exame?.possui_imagens !== true && b.exame?.possui_imagens === true) {
        return 1;
    }
    return 0;
};

const validationSchema = (strings) => {
    return yup.object().shape({
        [PROTOCOLO]: yup
            .string()
            .nullable()
            .required(strings.campoObrigatorio)
    })
}

const CampoProtocolo = ({ protocoloUrl }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [abrirModalDuvida, setAbrirModalDuvida] = useState(false);
    const inputProtocolo = useRef();
    const formRef = useRef(null);
    const imagemDuvidaAcessar = `${global.gConfig.url_base_exames}/exame/imagem-duvida`;
    const schema = validationSchema(strings);
    const isMobile = useMediaQuery({ maxWidth: 814 });

    let paramStyles = { basename: global.gConfig.basename };
    const classes = useStyles(paramStyles);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (inputProtocolo.current) {
            inputProtocolo.current.focus();
        }

        if (protocoloUrl && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, []);

    const handleOnClose = () => {
        alerta.value = { ...alerta.value, open: false };
    };

    const verificaExibicaoGaleria = (exame) => {
        if (exame.exame.sigla_modalidade == CONSTANTES_MODALIDADES.DERMATOLOGIA || exame.exame.sigla_modalidade == CONSTANTES_MODALIDADES.ESTOMATOLOGIA) {
            return false;
        }

        return true;
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                protocolo: protocoloUrl || ''
            }}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting }) => {
                navigate(`/protocolo/${dados.protocolo}`);
                carregando.value = {
                    ...carregando.value,
                    open: true
                };
                
                axios.get(`${global.gConfig.url_base_exames}/exame/protocolo/${encodeURIComponent(dados.protocolo)}`)
                    .then(function ({ data }) {
                        const exames = data.data.itens || [];
                        dadosExame.value = exames.sort(ordenarPelosExamesComImagens);
                        exameSelecionado.value = 0;
                        telaProtocolo.value = false;
                        menuSelecionado.value = SITUACAO.EXAME;
                        podeExibirGaleria.value = verificaExibicaoGaleria(exames[0]);
                    })
                    .catch(err => {
                        const { response } = err;
                        if (response) {
                            let mensagem = '';
                            let tipo = 'alert';
                            let titulo = strings.tituloAlerta;

                            const { status } = response;

                            if (status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                mensagem = strings.protocoloInvalido;
                            } else if (status === HttpStatus.NOT_FOUND) {
                                mensagem = strings.protocoloNaoExiste;
                            } else {
                                mensagem = strings.mensagemErroGeral;
                                tipo = 'error';
                                titulo = strings.tituloErro;
                            }

                            alerta.value = {
                                ...alerta.value,
                                open: true,
                                message: mensagem,
                                type: tipo,
                                title: titulo,
                                onClose: () => handleOnClose(),
                                options: [
                                    {
                                        title: strings.tituloOk,
                                        onClick: () => handleOnClose()
                                    }
                                ]
                            };
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        carregando.value = {
                            ...carregando.value,
                            open: false
                        };
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <SttBox className={classes.box} letterSpacing={1} width={600}>
                                <SttHeading
                                    variant="h2"
                                    color="primary"
                                    className={classes.heading}>
                                    {strings.acesseExame}
                                </SttHeading>

                                <Field name={PROTOCOLO}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <SttInputProtocol
                                            label={strings.protocolo}
                                            inputRef={inputProtocolo}
                                            className={classes.inputProtocolo}
                                            value={field.value ? field.value : ''}
                                            error={meta.touched && meta.error ? true : false}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                            {...field}
                                        />
                                    )}
                                </Field>

                                <SttHeading variant="h5"
                                    color="primary"
                                    className={classes.duvidasAcessar}
                                    onClick={(isMobile) ? () => downloadImagem(`${strings.tituloArquivoAcessoExames}.png`) : () => setAbrirModalDuvida(true)}>
                                    {strings.duvidasAcessar}
                                </SttHeading>

                                <SttButton
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained">
                                    {strings.acessarExame}
                                </SttButton>

                                <SttModal
                                    title={strings.titulo}
                                    open={abrirModalDuvida}
                                    className={classes.modal}
                                    iconClose={() => setAbrirModalDuvida(false)}
                                    outModalClose={() => setAbrirModalDuvida(false)}
                                    maxWidth="lg"
                                    children={
                                        <SttPaper className={classes.paper}>
                                            <img alt={strings.titulo} src={`${strings.tituloArquivoAcessoExames}.png`} className={classes.image}></img>
                                        </SttPaper>
                                    }
                                    footer={
                                        <div>
                                            <SttButton variant="contained"
                                                color="primary"
                                                onClick={() => setAbrirModalDuvida(false)}>
                                                {strings.tituloOk}
                                            </SttButton>
                                        </div>
                                    }
                                />
                            </SttBox>
                        </form>
                    )
                }
            }
        </Formik>


    );
};

export default CampoProtocolo;